import { withConfig } from "../utils/withConfig";

import Landing from "../components/pagesComponents/LandingPage/Landing";
import { LANDING_NAVBAR } from "../constants/headerTypes";
import {
  REVIEWS,
  TOP_CLUBS,
  // PARTNERS,
  GET_PARTNER_SETTINGS,
  GET_CONTACTS,
} from "../graphql/queries";
import { initializeApollo, addApolloState } from "../utils/appollo";
import {  SITE_URL } from "../constants/APIKeys";
import { getSeoFields } from "../utils/getSeoFields";

// export const getServerSideProps = async ({ locale, req }) => {
//   let club = req.headers.host.split(".")[0];
//   club =
//     club != process.env.CLIENT_LOCAL_HOST
//       ? club
//       : process.env.CLIENT_LOCAL_HOST;

//   return {
//     props: {
//       club,
//       ...(await withConfig(locale)),
//     },
//   };
// };

// export const getStaticProps = async ({ locale }) => {
//   return {
//     props: {
//       ...(await withConfig(locale)),
//     },
//   };
// };

export const getStaticProps = async ({ locale }) => {
  // if (process.env.OWN_DOMAIN)
  //   return {
  //     props: {
  //       ...(await withConfig(locale)),
  //     },
  //   };

  const apolloClient = initializeApollo({ locale });

  const queries = [
    { query: GET_PARTNER_SETTINGS, variables: { slug: "staff" } },
    { query: REVIEWS },
    { query: TOP_CLUBS },
    // { query: PARTNERS },
    { query: GET_CONTACTS, variables: { slug: "staff" } },
  ];
  const promises = queries.map(({ query, variables }) =>
    apolloClient.query({ query, variables })
  );
  const [{ data: { social = {} } = {} }] = await Promise.all(promises);

  const pageProps = {
    props: {
      tags: getSeoFields({
        ...social,
        alternate: `${SITE_URL}/${locale}`,
      }),
      locale,
      ...(await withConfig(locale)),
    },
  };

  return addApolloState(apolloClient, pageProps);
};

// export const config = {
//   runtime: 'experimental-edge',
// };

const LandingPage = () => {
  // if (process.env.OWN_DOMAIN) {
  //   return (
  //     <frameset>
  //       <frame
  //         src={`http://instasport.ua/club/${process.env.OWN_DOMAIN}`}
  //         marginHeight="0"
  //         marginWidth="0"
  //         scrolling="auto"
  //         frameBorder="0"
  //       />
  //     </frameset>
  //   );
  // }
  return <Landing />;
};

LandingPage.navbar = LANDING_NAVBAR;

export default LandingPage;
